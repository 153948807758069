.topNav .menu .menu-item {
  position: relative;
}
.topNav .menu .menu-item:hover .sub-menu {
  display: block;
}
.topNav .sub-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 250px;
  background: #FFFFFF;
  border-top: 2px solid #548a92;
  z-index: 1001;
}
.topNav .sub-menu .menu-item {
  margin: 10px 0;
}
.topNav .sub-menu .menu-item a {
  width: 100%;
  line-height: initial;
}
